const AgeRanges = [
  '<18',
  '18-49',
  '50-59',
  '60-64',
  '65-69',
  '70-74',
  '75-79',
  '80-100',
  '100+',
];

export const getAgeRange = (age: string): string | undefined => {
  const numericAge = +age;

  if (numericAge < 18) {
    return AgeRanges[0];
  }
  if (numericAge >= 100) {
    return AgeRanges[AgeRanges.length - 1];
  }

  return AgeRanges.slice(1, -1).find((range) => {
    const [min, max] = range.split('-').map(Number);
    return numericAge >= min && numericAge <= max;
  });
};

export default AgeRanges;
