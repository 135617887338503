import {
  BookingStep,
  BookingStepEnum,
  BookingStepValueEnum,
  BookingSubStep,
} from '../BookingWidget/bookingSteps.interface';

export interface QuotationInformationFormProps {
  [BookingStepEnum.Cards]: BookingSubStep[];
  activeStepConfig: BookingStep;
  nextStep: (step?: number) => void;
  previousStep: () => void;
  keyName: BookingStepValueEnum;
}

export enum FormQuotationInformationEnum {
  Title = 'title',
  Firstname = 'firstname',
  Lastname = 'lastname',
  Email = 'email',
  ReenterEmail = 'reenterEmail',
  Phone = 'phone',
  Address = 'address',
  Address2 = 'address2',
  City = 'city',
  State = 'state',
  Postcode = 'postcode',
  Birthdate = 'birthdate',
  Travellers = 'travellers',
  MedicalCondition1 = 'medicalCondition1',
  MedicalCondition2 = 'medicalCondition2',
  MedicalCondition3 = 'medicalCondition3',
  ExtraQuestion1 = 'extraQuestion1',
  ExtraQuestion2 = 'extraQuestion2',
  ExtraQuestion3 = 'extraQuestion3',
  ExtraQuestion4 = 'extraQuestion4',
  ExtraQuestion5 = 'extraQuestion5',
  ExtraQuestion6 = 'extraQuestion6',
  ExtraQuestion7 = 'extraQuestion7',
  ExtraQuestion8 = 'extraQuestion8',
  ExtraQuestion9 = 'extraQuestion9',
  ExtraQuestion10 = 'extraQuestion10',
  ExtraQuestion11 = 'extraQuestion11',
  ExtraQuestion12 = 'extraQuestion12',
}

export interface FormQuotationInformationPersonalData {
  [FormQuotationInformationEnum.Title]: string;
  [FormQuotationInformationEnum.Firstname]: string;
  [FormQuotationInformationEnum.Lastname]: string;
  [FormQuotationInformationEnum.Email]: string;
  [FormQuotationInformationEnum.ReenterEmail]?: string;
  [FormQuotationInformationEnum.Phone]: string;
  [FormQuotationInformationEnum.Address]: string;
  [FormQuotationInformationEnum.Address2]: string;
  [FormQuotationInformationEnum.City]: string;
  [FormQuotationInformationEnum.State]: string;
  [FormQuotationInformationEnum.Postcode]: string;
  [FormQuotationInformationEnum.Birthdate]: string;
}

export interface FormQuotationInformationMedicalData {
  [FormQuotationInformationEnum.MedicalCondition1]: string;
  [FormQuotationInformationEnum.MedicalCondition2]: string;
  [FormQuotationInformationEnum.MedicalCondition3]: string;
  [FormQuotationInformationEnum.ExtraQuestion1]: string;
  [FormQuotationInformationEnum.ExtraQuestion2]: string;
  [FormQuotationInformationEnum.ExtraQuestion3]: string;
  [FormQuotationInformationEnum.ExtraQuestion4]: string;
  [FormQuotationInformationEnum.ExtraQuestion5]: string;
  [FormQuotationInformationEnum.ExtraQuestion6]: string;
  [FormQuotationInformationEnum.ExtraQuestion7]: string;
  [FormQuotationInformationEnum.ExtraQuestion8]: string;
  [FormQuotationInformationEnum.ExtraQuestion9]: string;
  [FormQuotationInformationEnum.ExtraQuestion10]: string;
  [FormQuotationInformationEnum.ExtraQuestion11]: string;
  [FormQuotationInformationEnum.ExtraQuestion12]: string;
}

export enum FormQuotationInformationTravellerEnum {
  Firstname = 'firstname',
  Lastname = 'lastname',
  Age = 'age',
  BirthDate = 'birthDate',
  IsPolicyHolderTravelling = 'isPolicyHolderTravelling',
}

export interface FormQuotationInformationTraveller {
  [FormQuotationInformationTravellerEnum.Firstname]: string;
  [FormQuotationInformationTravellerEnum.Lastname]: string;
  [FormQuotationInformationTravellerEnum.Age]?: string;
  [FormQuotationInformationTravellerEnum.BirthDate]?: string;
  [FormQuotationInformationTravellerEnum.IsPolicyHolderTravelling]?: boolean;
}

export interface FormQuotationInformation
  extends FormQuotationInformationPersonalData,
    FormQuotationInformationMedicalData {
  [FormQuotationInformationEnum.Travellers]: FormQuotationInformationTraveller[];
}

export enum FormQuotationInformationErrorEnum {
  Title = 'titleError',
  Firstname = 'firstnameError',
  Lastname = 'lastnameError',
  Email = 'emailError',
  ReenterEmail = 'reenterEmailError',
  Phone = 'phoneError',
  Address = 'addressError',
  City = 'cityError',
  State = 'stateError',
  Postcode = 'postcodeError',
  Birthdate = 'birthdateError',
  Age = 'ageError',
  MedicalCondition2 = 'medicalCondition2Error',
  MedicalCondition3 = 'medicalCondition3Error',
  FormError = 'formError',
}

export interface FormQuotationInformationForm extends FormQuotationInformation {
  [FormQuotationInformationErrorEnum.Title]?: boolean;
  [FormQuotationInformationErrorEnum.Firstname]?: boolean;
  [FormQuotationInformationErrorEnum.Lastname]?: boolean;
  [FormQuotationInformationErrorEnum.Email]?: boolean;
  [FormQuotationInformationErrorEnum.ReenterEmail]?: boolean;
  [FormQuotationInformationErrorEnum.Phone]?: boolean;
  [FormQuotationInformationErrorEnum.Address]?: boolean;
  [FormQuotationInformationErrorEnum.City]?: boolean;
  [FormQuotationInformationErrorEnum.State]?: boolean;
  [FormQuotationInformationErrorEnum.Postcode]?: boolean;
  [FormQuotationInformationErrorEnum.Birthdate]?: boolean;
  [FormQuotationInformationErrorEnum.Age]?: boolean;
  [FormQuotationInformationErrorEnum.MedicalCondition2]?: boolean;
  [FormQuotationInformationErrorEnum.MedicalCondition3]?: boolean;
  [FormQuotationInformationErrorEnum.FormError]?: boolean;
}

export const FormInformationPersonalInformation: FormQuotationInformationEnum[] =
  [
    FormQuotationInformationEnum.Title,
    FormQuotationInformationEnum.Firstname,
    FormQuotationInformationEnum.Lastname,
    FormQuotationInformationEnum.Email,
    FormQuotationInformationEnum.Phone,
    FormQuotationInformationEnum.Address,
    FormQuotationInformationEnum.Address2,
    FormQuotationInformationEnum.City,
    FormQuotationInformationEnum.State,
    FormQuotationInformationEnum.Postcode,
    FormQuotationInformationEnum.Birthdate,
  ];

export const FormInformationMedicalData: FormQuotationInformationEnum[] = [
  FormQuotationInformationEnum.MedicalCondition1,
  FormQuotationInformationEnum.MedicalCondition2,
  FormQuotationInformationEnum.MedicalCondition3,
  FormQuotationInformationEnum.ExtraQuestion1,
  FormQuotationInformationEnum.ExtraQuestion2,
  FormQuotationInformationEnum.ExtraQuestion3,
  FormQuotationInformationEnum.ExtraQuestion4,
  FormQuotationInformationEnum.ExtraQuestion5,
  FormQuotationInformationEnum.ExtraQuestion6,
  FormQuotationInformationEnum.ExtraQuestion7,
  FormQuotationInformationEnum.ExtraQuestion8,
  FormQuotationInformationEnum.ExtraQuestion9,
  FormQuotationInformationEnum.ExtraQuestion10,
  FormQuotationInformationEnum.ExtraQuestion11,
  FormQuotationInformationEnum.ExtraQuestion12,
];

export interface FormQuotationInformationTravellerList {
  travellerAge0?: string;

  travellerAge1?: string;
  travellerFirstName1?: string;
  travellerLastName1?: string;

  travellerAge2?: string;
  travellerFirstName2?: string;
  travellerLastName2?: string;

  travellerAge3?: string;
  travellerFirstName3?: string;
  travellerLastName3?: string;

  travellerAge4?: string;
  travellerFirstName4?: string;
  travellerLastName4?: string;

  travellerAge5?: string;
  travellerFirstName5?: string;
  travellerLastName5?: string;

  travellerAge6?: string;
  travellerFirstName6?: string;
  travellerLastName6?: string;

  travellerAge7?: string;
  travellerFirstName7?: string;
  travellerLastName7?: string;

  travellerAge8?: string;
  travellerFirstName8?: string;
  travellerLastName8?: string;

  travellerAge9?: string;
  travellerFirstName9?: string;
  travellerLastName9?: string;

  travellerAge10?: string;
  travellerFirstName10?: string;
  travellerLastName10?: string;
}

export interface MedicalDeclarationAndExtraQuestion {
  orderId: number;
  value: string;
  level: number;
  question: string;
}
