const getUrlSearchParam = (
  param: string,
  searchParams: URLSearchParams,
): string | null => {
  const lowerCaseSearchParams: URLSearchParams = new URLSearchParams();
  Array.from(searchParams.entries()).forEach(([keyT, valueT]): void =>
    lowerCaseSearchParams.append(keyT.toLowerCase(), valueT),
  );
  return lowerCaseSearchParams.get(param.toLowerCase());
};

export default getUrlSearchParam;
