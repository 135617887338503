import {
  Companion,
  Customer as CustomerInterface,
} from './BookingManagment/bookingManagment.interface';

export enum BookingMeResponseEnum {
  Customer = 'customer',
  Companions = 'companions',
}

export interface BookingMeResponse {
  [BookingMeResponseEnum.Customer]: CustomerInterface;
  [BookingMeResponseEnum.Companions]: Companion[];
}
