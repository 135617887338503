import React, { createContext, useContext, useState } from 'react';
import dayjs from 'dayjs';
import {
  BookingContextState,
  BookingStepsContextState,
  BookingSubStepsQuotationForm,
  SaveBookingStep,
} from './bookingContext.types';
import { getToken } from '../../shared/helpers/auth.helper';
import {
  BookingConfig,
  BookingConfigEnum,
  BookingStep,
  BookingStepEnum,
  BookingStepValueEnum,
  BookingSubStep,
  BookingSubStepEnum,
  BookingSubStepOption,
  BookingSubStepOptionEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import { getDefaultValuesForAddonsAndOptions } from './bookingContext.helper';
import saveBooking from '../../shared/services/bookingData.service';
import {
  BookingQuoteResponse,
  BookingQuoteResponseEnum,
  BookingQuoteResponseQuoteEnum,
} from '../../components/BookingWidget/BookingQuoteManagment/bookingQuoteResponse.interface';
import contextDefaultValues from './bookingContext.const';
import { BookingConfigContext } from '../bookingConfigContext/bookingConfigContext';
import { BookingQuoteContext } from '../quoteContext/quoteContext';
import createQuote from '../../shared/services/quoteData.service';
import { BookingDataContext } from '../bookingDataContext/bookingDataContext';
import preparePricingPayload from '../../components/QuotationSummaryBlock/preparePricingPayload';
import deepMerge from '../../shared/helpers/deepMergeObjects';
import getCatalogLabel from '../../components/QuotationForm/components/tripDetails.mapper';
import { getAgeRange } from '../../components/QuotationForm/components/TravellersAgeTiles/travellers-age.const';
import { FormQuotationInformationEnum } from '../../components/QuotationInformationForm/quoation-information.interface';
import useApi from '../../shared/services/api.service';
import {
  insertAddonsIntoObject,
  insertOptionsIntoObject,
} from '../../shared/helpers/insuranceOptions';
import ErrorLoggingService from '../../shared/services/errorlogging.service';
import { BookingDataResponse } from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';
import { LoadingContext } from '../loadingContext/loadingContext';
import { NotificationContext } from '../../shared/components/Notification/NotificationContext';
import useSendQuoteEmail from '../../shared/helpers/useSendQuoteEmail';

export const BookingContext =
  createContext<BookingContextState>(contextDefaultValues);

export const BookingContextProvider: React.FC<
  React.PropsWithChildren<object>
> = ({ children }) => {
  const { setIsLoading } = useContext(LoadingContext);
  const errorService: ErrorLoggingService = ErrorLoggingService.getInstance();
  const [bookingSteps, setBookingSteps] = useState<BookingStepsContextState>(
    contextDefaultValues.bookingSteps,
  );
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { bookingDataResponse, updateBookingData } =
    useContext(BookingDataContext);
  const { bookingQuoteResponse, updateQuote } = useContext(BookingQuoteContext);
  const API = useApi(bookingConfigData);
  const { showNotification } = useContext(NotificationContext);
  const { sendQuote } = useSendQuoteEmail();

  const getPricingData = async (): Promise<string | void> => {
    const apiCM360Url: string =
      bookingConfigData?.[BookingConfigEnum.DataCenter]?.cm360Endpoint;
    const quotationFormData: BookingSubStepsQuotationForm =
      bookingSteps[BookingStepValueEnum.QuotationForm];
    const url = `${apiCM360Url}/ui-proxy/ws-partners/api/platforms/${
      bookingConfigData[BookingConfigEnum.DataCenter].psPlatform
    }/catalogs/${
      quotationFormData[BookingSubStepValueEnum.TripDetails][
        BookingSubStepOptionEnum.Value
      ]
    }/pricing`;
    return API.post(
      url,
      preparePricingPayload(
        bookingSteps,
        bookingConfigData,
        bookingDataResponse,
      ),
      {
        headers: {
          'Client-Id': bookingConfigData[BookingConfigEnum.DataCenter].psClient,
        },
      },
    )
      .then((res: any) => res.data)
      .then((res: any) => {
        if (!res.products[0].errorMessage) {
          const priceNet = res.products[0].totalPrice.netPrice;
          return priceNet;
        }
        return 0;
      });
  };

  async function saveBookingData(
    bookingStepsData: BookingStepsContextState,
  ): Promise<void> {
    const apiCM360Url: string =
      bookingConfigData?.[BookingConfigEnum.DataCenter]?.cm360Endpoint;
    try {
      const bookingData = await saveBooking(
        apiCM360Url,
        bookingStepsData,
        bookingConfigData,
        bookingQuoteResponse as BookingQuoteResponse,
        bookingDataResponse,
      );
      updateBookingData(bookingData);
    } catch (error) {
      showNotification('updatingData', 'error', false);
      errorService.log('Update context error', error);
    }
  }

  const sendQuoteEmailAutomatically = (
    bookingStepsData: BookingStepsContextState,
    quoteId: string,
    currentStep?: string,
  ): void => {
    const shouldSendEmail = bookingConfigData[
      BookingConfigEnum.BookingSteps
    ].find(({ keyName }) => keyName === currentStep)?.sendQuoteOnEnterStep;

    if (shouldSendEmail) {
      const { email } =
        bookingStepsData.quotationInformationForm.informationPersonal;
      sendQuote(email, bookingStepsData, quoteId);
    }
  };

  async function saveQuoteAndBookingData(
    bookingStepsData: BookingStepsContextState,
    currentStep?: BookingStepValueEnum,
    nextStep?: (step?: number | undefined) => void,
  ): Promise<SaveBookingStep | null> {
    const apiCM360Url: string =
      bookingConfigData?.[BookingConfigEnum.DataCenter]?.cm360Endpoint;
    try {
      // @TODO temporary fix, to make sure that async requests are done
      if (currentStep === BookingStepValueEnum.QuotationRecapDeclaration) {
        setIsLoading(true);
      }
      const price = await getPricingData();
      const bookingStepsToSave = {
        ...bookingStepsData,
        ...(currentStep && { currentStep }),
        [BookingStepValueEnum.QuotationProposals]: {
          ...bookingStepsData[BookingStepValueEnum.QuotationProposals],
          [BookingSubStepValueEnum.TotalPrice]: +price,
        },
      };

      const decodedToken = getToken();

      // @TODO temporary fix, to make sure that async requests are done
      if (currentStep === BookingStepValueEnum.QuotationRecapDeclaration) {
        setIsLoading(true);
      }

      const quoteResponse: BookingQuoteResponse = await createQuote(
        apiCM360Url,
        bookingStepsToSave,
        bookingConfigData,
        { agentEmail: decodedToken?.email },
        bookingDataResponse,
      );

      updateQuote(quoteResponse);
      const bookingData = await saveBooking(
        apiCM360Url,
        bookingStepsToSave,
        bookingConfigData,
        quoteResponse,
        bookingDataResponse,
      );
      // @TODO temporary fix, to make sure that async requests are done
      const quoteId =
        quoteResponse[BookingQuoteResponseEnum.Quotes][0][
          BookingQuoteResponseQuoteEnum.QuoteId
        ];
      sendQuoteEmailAutomatically(bookingStepsData, quoteId, currentStep);

      if (currentStep === BookingStepValueEnum.QuotationRecapDeclaration) {
        setIsLoading(true);
      }
      updateBookingData(bookingData);
      if (nextStep) {
        nextStep();
      }

      return { bookingData, quoteResponse };
    } catch (error) {
      showNotification('updatingData', 'error', false);
      errorService.log('Update context error', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  }

  const handleUpdate = async (
    value: any,
    step: BookingStepValueEnum,
    subStep?: BookingSubStepValueEnum,
    updateBookingAndQuote?: boolean,
    currentStep?: BookingStepValueEnum,
    updateOnlyBookingData?: boolean,
    nextStep?: (step?: number | undefined) => void,
  ): Promise<BookingStepsContextState> => {
    if (!step && !subStep) {
      setBookingSteps(value);
      return value;
    }
    if (subStep) {
      const updatedState = { ...bookingSteps };
      const stepToUpdate = bookingSteps[step] as unknown as {
        [key: string]: unknown;
      };
      stepToUpdate[subStep] = value;
      updatedState[BookingStepValueEnum.CurrentStep] = step;
      setBookingSteps(updatedState);
      if (updateBookingAndQuote) {
        await saveQuoteAndBookingData(updatedState, currentStep, nextStep);
      } else if (updateOnlyBookingData) {
        await saveBookingData(updatedState);
      }
      return updatedState;
    }
    const updatedState = { ...bookingSteps, [step]: value };
    updatedState[BookingStepValueEnum.CurrentStep] = step;
    setBookingSteps(updatedState);
    if (updateBookingAndQuote) {
      await saveQuoteAndBookingData(updatedState, currentStep, nextStep);
    } else if (updateOnlyBookingData) {
      await saveBookingData(updatedState);
    }
    return updatedState;
  };

  const handleUpdateAndSaveOneStep = async (
    value: any,
    step: BookingStepValueEnum,
  ): Promise<SaveBookingStep | null> => {
    const updatedState = { ...bookingSteps, [step]: value };
    updatedState[BookingStepValueEnum.CurrentStep] = step;
    setBookingSteps(updatedState);
    return saveQuoteAndBookingData(updatedState);
  };

  const handleInitDefaultValues = (bookingConfig: BookingConfig): void => {
    const currentSteps = { ...bookingSteps };
    const quotationProposals = bookingConfig[
      BookingConfigEnum.BookingSteps
    ].filter(
      (step: BookingStep): boolean =>
        step[BookingStepEnum.KeyName] === 'quotationProposals',
    )[0];
    const addonsCard = quotationProposals[BookingStepEnum.Cards].filter(
      (card: BookingSubStep): boolean =>
        card[BookingSubStepEnum.KeyName] === BookingSubStepValueEnum.Addons,
    )[0];
    const optionsCard = quotationProposals[BookingStepEnum.Cards].filter(
      (card: BookingSubStep): boolean =>
        card[BookingSubStepEnum.KeyName] === BookingSubStepValueEnum.Options,
    )[0];
    currentSteps[BookingStepValueEnum.QuotationProposals][
      BookingSubStepValueEnum.Addons
    ] = getDefaultValuesForAddonsAndOptions(
      addonsCard[BookingSubStepEnum.Options] as BookingSubStepOption[],
    );
    currentSteps[BookingStepValueEnum.QuotationProposals][
      BookingSubStepValueEnum.Options
    ] = getDefaultValuesForAddonsAndOptions(
      optionsCard[BookingSubStepEnum.Options] as BookingSubStepOption[],
    );
    setBookingSteps(currentSteps);
  };

  const loadBooking = (booking: any): void => {
    const { bookingData, companions, customer, consents } = booking;
    const quotationsProposalsConfig = bookingConfigData.bookingSteps?.find(
      (s) => s.keyName === 'quotationProposals',
    );
    const quotationProposalsOptionsConfig =
      quotationsProposalsConfig?.cards?.find((c) => c.keyName === 'options')
        ?.options;
    const quotationsFormConfig = bookingConfigData.bookingSteps?.find(
      (s) => s.keyName === 'quotationForm',
    );
    const geographicalZoneSelectedOption = (
      quotationsFormConfig?.cards.find(
        (card) => card.keyName === BookingSubStepValueEnum.GeographicalZone,
      )?.options as any
    ).find((o: any) => o.value === bookingData.destination);
    const updatedBookingSteps = {
      quotationPreliminaryDeclarations: {
        [BookingSubStepValueEnum.InformationPersonal]: {
          [FormQuotationInformationEnum.Title]: customer.title,
          [FormQuotationInformationEnum.Firstname]: customer.firstName,
          [FormQuotationInformationEnum.Lastname]: customer.lastName,
        },
        [BookingSubStepValueEnum.PreflightConsents]: consents
          .map((c: any) =>
            (
              bookingConfigData.bookingSteps[0].cards.find(
                (card) =>
                  card.keyName === BookingSubStepValueEnum.PreflightConsents,
              )?.options as any
            ).find((o: any) => o.usageType === c.usages[0].type),
          )
          .filter((o: any) => !!o),
      },
      quotationForm: {
        tripDetails: {
          value: bookingData.catalog,
          label: getCatalogLabel(bookingData.catalog),
        },
        geographicalZone: {
          label: geographicalZoneSelectedOption.label,
          value: geographicalZoneSelectedOption.value,
        },
        // TODO: FIX
        travellersType: {
          value: 'individual',
          label: 'individual',
        },
        travellersAge: companions.map((c: any) =>
          getAgeRange(dayjs().diff(c.dateOfBirth, 'year').toString()),
        ),
        promoCode: bookingData.promoCode,
      },
      quotationProposals: {
        options: getDefaultValuesForAddonsAndOptions(
          quotationProposalsOptionsConfig as any,
        ),
      },
    };

    const merged = deepMerge(bookingSteps, updatedBookingSteps);
    merged.quotationForm.departureDate = dayjs(bookingData.startDate);
    merged.quotationForm.returnDate = dayjs(bookingData.endDate);

    setBookingSteps(merged);
  };

  const loadQuote = (dataToLoad: {
    quote: any;
    medicalConditions: any;
    consents: any[];
    bookingResponse: BookingDataResponse;
    latestBookingSteps: BookingStepsContextState;
  }): void => {
    const { quote, insurance, subscriber, beneficiaries } = dataToLoad.quote;
    const { medicalConditions, consents, bookingResponse, latestBookingSteps } =
      dataToLoad;
    const quotationsFormConfig = bookingConfigData.bookingSteps?.find(
      (s) => s.keyName === 'quotationForm',
    );
    const quotationsProposalsConfig = bookingConfigData.bookingSteps?.find(
      (s) => s.keyName === 'quotationProposals',
    );
    const proposalsOptions = insertOptionsIntoObject(
      quote?.genericData?.insuranceOptions,
    );

    const proposalsAddons = insertAddonsIntoObject(
      quote?.genericData?.insuranceAddons,
    );

    const formattedCustomer = subscriber && {
      [FormQuotationInformationEnum.Title]: subscriber.civility,
      [FormQuotationInformationEnum.Firstname]: subscriber.firstName,
      [FormQuotationInformationEnum.Lastname]: subscriber.lastName,
      [FormQuotationInformationEnum.Email]: subscriber.email,
      [FormQuotationInformationEnum.ReenterEmail]: subscriber.email,
      [FormQuotationInformationEnum.Phone]: subscriber.phone,
      [FormQuotationInformationEnum.Address]: subscriber.address1,
      [FormQuotationInformationEnum.Address2]: subscriber.address2,
      [FormQuotationInformationEnum.City]: subscriber.city,
      [FormQuotationInformationEnum.State]:
        insurance.insuranceData?.state ||
        bookingResponse.customer?.addressLine4 ||
        bookingResponse.bookingData.region,
      [FormQuotationInformationEnum.Postcode]: subscriber.postalCode,
      [FormQuotationInformationEnum.Birthdate]: subscriber.birthDate,
    };
    const catalog = `${quote.product.iac}.${quote.product.ipc.toUpperCase()}`;
    const informationMedical: any = {};

    const medicalScreening = medicalConditions?.map((medicalData: any) =>
      medicalData
        ? {
            conditions: medicalData.conditions,
            isCovered: medicalData.isCovered,
          }
        : null,
    );
    const medicalDeclarations = medicalConditions?.find(
      (m: any) => m && m.declarations,
    )?.declarations;

    medicalDeclarations?.forEach((d: any, i: number) => {
      if (d.level === 1) {
        informationMedical[`medicalCondition${i + 1}`] = d.answer;
      } else {
        informationMedical[
          `extraQuestion${
            i +
            1 -
            medicalDeclarations.findIndex(
              (declaration: any) => declaration.level === 2,
            )
          }`
        ] = d.answer;
      }
    });

    const geographicalZoneSelectedOption = (
      quotationsFormConfig?.cards.find(
        (card) => card.keyName === BookingSubStepValueEnum.GeographicalZone,
      )?.options as any
    ).find((o: any) => o.value === quote.genericData.geographicalZone);

    const { maxTripDuration, isFamilyOrCouple } = quote.genericData;
    const maxTripDurationSelectedOption =
      maxTripDuration &&
      (
        quotationsFormConfig?.cards.find(
          (card) => card.keyName === BookingSubStepValueEnum.MaxTripDuration,
        )?.options as any
      ).find((o: any) => o.value === maxTripDuration);
    const travellersTypeSelectedOption = (
      quotationsFormConfig?.cards.find(
        (card) => card.keyName === BookingSubStepValueEnum.TravellersType,
      )?.options as any
    ).find((o: any) =>
      isFamilyOrCouple === '1'
        ? o.value === 'couple-or-family'
        : o.value === 'individual',
    );

    const medicalDisclaimerConsent = consents?.find(
      (c) =>
        c.usages &&
        c.usages.length > 0 &&
        c.usages[0].type === 'MEDICAL_DISCLAIMER_CONSENT',
    );

    const additionalValues = quote.product?.additionalValues?.[0]?.data;

    const updatedBookingSteps = {
      affiliateId: quote.genericData.partnerCode,
      quotationPreliminaryDeclarations: {
        [BookingSubStepValueEnum.InformationPersonal]: {
          [FormQuotationInformationEnum.Title]:
            formattedCustomer[FormQuotationInformationEnum.Title],
          [FormQuotationInformationEnum.Firstname]:
            formattedCustomer[FormQuotationInformationEnum.Firstname],
          [FormQuotationInformationEnum.Lastname]:
            formattedCustomer[FormQuotationInformationEnum.Lastname],
        },
        [BookingSubStepValueEnum.PreflightConsents]: consents
          .map((c) =>
            (
              bookingConfigData.bookingSteps[0].cards.find(
                (card) =>
                  card.keyName === BookingSubStepValueEnum.PreflightConsents,
              )?.options as any
            ).find((o: any) => o.usageType === c.usages?.[0]?.type),
          )
          .filter((o: any) => !!o),
      },
      quotationForm: {
        tripDetails: {
          value: catalog,
          label: getCatalogLabel(catalog),
        },
        geographicalZone: {
          label: geographicalZoneSelectedOption.label,
          value: geographicalZoneSelectedOption.value,
        },
        ...(maxTripDurationSelectedOption
          ? {
              maxTripDuration: {
                label: maxTripDurationSelectedOption.label,
                value: maxTripDurationSelectedOption.value,
              },
            }
          : {}),
        travellersType: travellersTypeSelectedOption,
        travellersAge: beneficiaries.map((b: any) =>
          getAgeRange(dayjs().diff(b.birthDate, 'year').toString()),
        ),
        promoCode: quote.product.price.promotionCode,
      },
      quotationProposals: {
        proposal: {
          code: quote.product.code,
          description: bookingResponse?.bookingData?.productName,
        },
        addons: proposalsAddons,
        options: proposalsOptions,
        ...((quote.genericData.dd_amount || quote.genericData.dd_rate) && {
          agentsDiscount: {
            discountAmount:
              quote.genericData.dd_amount ||
              `${quote.genericData.dd_rate * 100}`,
            isPercentageDiscount: !!quote.genericData.dd_rate,

            discountStatus: 'APPROVED',
            selectedDiscountReason: quote.genericData.dd_reason,
          },
        }),
        [BookingSubStepValueEnum.PromoRate]: additionalValues?.promo_rate,
        [BookingSubStepValueEnum.FamCoupleRate]:
          additionalValues?.fam_couple_rate,
      },
      quotationInformationForm: {
        ...(medicalDisclaimerConsent
          ? {
              [BookingSubStepValueEnum.MedicalDisclaimerConsent]: {
                usageType: 'MEDICAL_DISCLAIMER_CONSENT',
                agentExlusive: false,
                version: 1,
              },
            }
          : {}),
        [BookingSubStepValueEnum.MedicalPriceBreakdown]:
          additionalValues?.medical_breakdown,
        [BookingSubStepValueEnum.InformationPersonal]: formattedCustomer,
        [BookingSubStepValueEnum.InformationMedical]: informationMedical,
        [BookingSubStepValueEnum.MedicalScreening]: medicalScreening,
      },
    };
    const merged = deepMerge(latestBookingSteps, updatedBookingSteps);
    merged.quotationForm.departureDate = dayjs(insurance.startDate);
    merged.quotationForm.returnDate = dayjs(insurance.endDate);

    const informationTravellers: any = {};
    beneficiaries.forEach((b: any, index: number) => {
      if (index > 0) {
        informationTravellers[`travellerFirstName${index}`] = b.firstName;
        informationTravellers[`travellerLastName${index}`] = b.lastName;
        informationTravellers[`travellerAge${index}`] = dayjs().diff(
          dayjs(b.birthDate),
          'year',
        );
      } else {
        informationTravellers[`travellerAge${index}`] = dayjs(b.birthDate);
      }
    });

    merged.quotationInformationForm[
      BookingSubStepValueEnum.InformationTravellers
    ] = informationTravellers;

    setBookingSteps(merged);
  };

  return (
    <BookingContext.Provider // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        bookingSteps,
        update: handleUpdate,
        updateAndSaveOneStep: handleUpdateAndSaveOneStep,
        updateDefault: handleInitDefaultValues,
        loadQuote,
        loadBooking,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};
