import React, { createContext, useMemo, useState } from 'react';
import { BookingQuoteResponse } from '../../components/BookingWidget/BookingQuoteManagment/bookingQuoteResponse.interface';
import { BookingQuoteContextState } from './quoteContext.types';

const contextDefaultValues: BookingQuoteContextState = {
  bookingQuoteResponse: null!,
  updateQuote: (bookingQuoteResponse: BookingQuoteResponse): void => undefined,
};
export const BookingQuoteContext =
  createContext<BookingQuoteContextState>(contextDefaultValues);

export const BookingQuoteContextProvider: React.FC<
  React.PropsWithChildren<object>
> = ({ children }) => {
  const [bookingQuoteResponse, setQuote] =
    useState<BookingQuoteResponse | null>(
      contextDefaultValues.bookingQuoteResponse,
    );

  const handleUpdate = (bookingQuote: BookingQuoteResponse): void => {
    setQuote(bookingQuote);
  };

  const bookingQuote: BookingQuoteContextState = useMemo(
    () => ({ bookingQuoteResponse, updateQuote: handleUpdate }),
    [bookingQuoteResponse],
  );

  return (
    <BookingQuoteContext.Provider value={bookingQuote}>
      {children}
    </BookingQuoteContext.Provider>
  );
};
