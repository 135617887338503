import { BookingStepsContextState } from '../../context/bookingContext/bookingContext.types';
import {
  BookingConfig,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../BookingWidget/bookingSteps.interface';
import { getSubscriberObject } from '../../shared/helpers/subscriber.helper';
import getBeneficiariesArray from '../../shared/helpers/beneficiaries.helper';
import getInsurancePayload from '../../shared/helpers/getInsurancePayload.helper';
import {
  PricingPayload,
  PricingPayloadEnum,
  ProductsEnum,
} from './pricingPayload.interfaces';
import { BookingDataResponse } from '../BookingWidget/BookingManagment/bookingManagment.interface';

const preparePricingPayload = (
  bookingSteps: BookingStepsContextState,
  bookingConfigData: BookingConfig,
  bookingDataResponse: BookingDataResponse | null,
): PricingPayload => ({
  [PricingPayloadEnum.Products]: [
    {
      [ProductsEnum.Reference]: '01',
      [ProductsEnum.ProductGroup]: [
        {
          code: `${
            bookingSteps[BookingStepValueEnum.QuotationProposals][
              BookingSubStepValueEnum.Proposal
            ].code
          }`,
        },
      ],
    },
  ],
  [PricingPayloadEnum.Insurance]: getInsurancePayload(
    bookingSteps,
    bookingConfigData,
  ),
  [PricingPayloadEnum.Subscriber]: getSubscriberObject(
    bookingConfigData,
    bookingSteps,
  ),
  [PricingPayloadEnum.Beneficiaries]: getBeneficiariesArray(
    bookingSteps,
    bookingConfigData,
    bookingDataResponse,
  ),
  [PricingPayloadEnum.Promotion]: {
    promotionCode:
      bookingSteps.quotationForm[BookingSubStepValueEnum.PromoCode],
  },
});

export default preparePricingPayload;
