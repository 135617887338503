const setCookie = (name: string, value: string, days: number): void => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};

const deleteCookie = (name: string): void => {
  document.cookie = `${name}=;expires=${new Date(0)};path=/;`;
};

const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (!!parts && parts.length === 2) {
    return parts?.pop()?.split(';')?.shift();
  }
  return undefined;
};

export { setCookie, deleteCookie, getCookie };
