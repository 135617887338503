import { createTheme } from '@mui/material/styles';
import { Theme } from '@mui/material';
import getFontName from './theme.helper';
import { BookingStyleColorsEnum } from '../shared/interfaces/bookingStyles.interface';

export const createAppThemeAPI = (paletteColors: any, brand: string): Theme => {
  const buttonPrimaryTextColor =
    brand !== 'igo'
      ? paletteColors[BookingStyleColorsEnum.LightPrimary]
      : paletteColors[BookingStyleColorsEnum.DarkPrimary];
  return createTheme({
    typography: {
      fontFamily: getFontName(brand),
    },
    palette: {
      primary: {
        main: paletteColors[BookingStyleColorsEnum.Primary],
      },
      secondary: {
        main: '#11cb5f',
      },
      info: {
        main: paletteColors[BookingStyleColorsEnum.DarkMiddle],
      },
      error: {
        main: '#ff0000',
      },
      text: {
        primary: paletteColors[BookingStyleColorsEnum.DarkPrimary],
        secondary: paletteColors[BookingStyleColorsEnum.DarkSecondary],
        disabled: '#b7b7b7',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '&.MuiButton-containedPrimary': {
              backgroundColor: paletteColors[BookingStyleColorsEnum.Primary], // your custom primary color for the button background
              color: buttonPrimaryTextColor, // your custom color for the button text
              zIndex: 20,
              '&:hover': {
                backgroundColor: paletteColors[BookingStyleColorsEnum.Primary], // your custom hover color for the button background
                color: buttonPrimaryTextColor, // your custom hover color for the button text
              },
              '&.contrast': {
                color: paletteColors[BookingStyleColorsEnum.ContrastPrimary],
              },
            },
            '&.MuiButton-containedSecondary': {
              backgroundColor:
                paletteColors[BookingStyleColorsEnum.LightSecondary], // your custom primary color for the button background
              color: paletteColors[BookingStyleColorsEnum.DarkPrimary], // your custom color for the button text
              '&:hover': {
                backgroundColor:
                  paletteColors[BookingStyleColorsEnum.LightSecondary], // your custom hover color for the button background
                color: paletteColors[BookingStyleColorsEnum.DarkPrimary], // your custom hover color for the button text
              },
            },
            // '&.MuiButton-outlined': {
            //   color: paletteColors.textPrimary, // your custom primary color for the button text
            // },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: paletteColors[BookingStyleColorsEnum.Primary],
              color: paletteColors[BookingStyleColorsEnum.DarkPrimary],
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              // Make sure to target .Mui-selected class for selected state
              backgroundColor: `${
                paletteColors[BookingStyleColorsEnum.Primary]
              } !important`,
              color: paletteColors[BookingStyleColorsEnum.DarkPrimary],
            },
            '&.root-MuiPickersDay-root.Mui-selected': {
              backgroundColor: '#d30000 !important',
            },
            '&.Mui-disabled': {
              opacity: 0.4,
            },
            '&.MuiPickersDay': {
              root: {
                '&.Mui-selected': {
                  backgroundColor:
                    paletteColors[BookingStyleColorsEnum.Primary],
                  color: paletteColors[BookingStyleColorsEnum.LightPrimary],
                },
              },
            },
            '&.MuiToggleButton': {
              root: {
                '&.Mui-selected': {
                  backgroundColor:
                    paletteColors[BookingStyleColorsEnum.Primary],
                  color: paletteColors[BookingStyleColorsEnum.LightPrimary],
                },
              },
            },
          },
        },
      },
      // MuiPickersDay: {
      //   styleOverrides: {
      //     root: {
      //       '&.Mui-selected': {
      //         backgroundColor: 'red', // Your custom color for selected date
      //         color: 'white', // Text color for selected date
      //         '&:hover': {
      //           backgroundColor: 'darkred', // Your custom color for selected date on hover
      //         },
      //       },
      //     },
      //   },
      // },
      MuiLinearProgress: {
        styleOverrides: {
          bar: {
            backgroundColor: paletteColors[BookingStyleColorsEnum.Primary],
          },
          colorPrimary: {
            backgroundColor:
              paletteColors[BookingStyleColorsEnum.LightSecondary],
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: `${buttonPrimaryTextColor} !important`,
            },
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            'text.MuiStepIcon-text': {
              fill: buttonPrimaryTextColor,
            },
            '.MuiSvgIcon-root.Mui-completed': {
              background: `radial-gradient(circle at 50%, ${buttonPrimaryTextColor} 40%, transparent 60%)`,
            },
          },
        },
      },
    },
  });
};
const createAppTheme = (paletteColors: any, brand: string): Theme => {
  const buttonPrimaryTextColor =
    brand !== 'igo' ? paletteColors.lightPrimary : paletteColors.darkPrimary;
  return createTheme({
    typography: {
      fontFamily: getFontName(brand),
    },
    palette: {
      primary: {
        main: paletteColors.primary,
      },
      secondary: {
        main: '#11cb5f',
      },
      error: {
        main: '#ff0000',
      },
      text: {
        primary: paletteColors.darkPrimary,
        secondary: paletteColors.darkSecondary,
        disabled: '#b7b7b7',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '&.MuiButton-containedPrimary': {
              backgroundColor: paletteColors.primary, // your custom primary color for the button background
              color: buttonPrimaryTextColor, // your custom color for the button text
              '&:hover': {
                backgroundColor: paletteColors.primary, // your custom hover color for the button background
                color: buttonPrimaryTextColor, // your custom hover color for the button text
              },
            },
            '&.MuiButton-containedSecondary': {
              backgroundColor: paletteColors.lightSecondary, // your custom primary color for the button background
              color: paletteColors.darkPrimary, // your custom color for the button text
              '&:hover': {
                backgroundColor: paletteColors.lightSecondary, // your custom hover color for the button background
                color: paletteColors.darkPrimary, // your custom hover color for the button text
              },
            },
            // '&.MuiButton-outlined': {
            //   color: paletteColors.textPrimary, // your custom primary color for the button text
            // },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: paletteColors.primary,
              color: paletteColors.lightPrimary,
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              // Make sure to target .Mui-selected class for selected state
              backgroundColor: `${paletteColors.primary} !important`,
              color: paletteColors.lightPrimary,
            },
            '&.root-MuiPickersDay-root.Mui-selected': {
              backgroundColor: '#d30000 !important',
            },
            '&.Mui-disabled': {
              opacity: 0.4,
            },
            '&.MuiPickersDay': {
              root: {
                '&.Mui-selected': {
                  backgroundColor: paletteColors.primary,
                  color: paletteColors.lightPrimary,
                },
              },
            },
            '&.MuiToggleButton': {
              root: {
                '&.Mui-selected': {
                  backgroundColor: paletteColors.primary,
                  color: paletteColors.lightPrimary,
                },
              },
            },
          },
        },
      },
      // MuiPickersDay: {
      //   styleOverrides: {
      //     root: {
      //       '&.Mui-selected': {
      //         backgroundColor: 'red', // Your custom color for selected date
      //         color: 'white', // Text color for selected date
      //         '&:hover': {
      //           backgroundColor: 'darkred', // Your custom color for selected date on hover
      //         },
      //       },
      //     },
      //   },
      // },
      MuiLinearProgress: {
        styleOverrides: {
          bar: {
            backgroundColor: paletteColors.primary,
          },
          colorPrimary: {
            backgroundColor: paletteColors.lightSecondary,
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: `${buttonPrimaryTextColor} !important`,
            },
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            'text.MuiStepIcon-text': {
              fill: buttonPrimaryTextColor,
            },
            '.MuiSvgIcon-root.Mui-completed': {
              background: `radial-gradient(circle at 50%, ${buttonPrimaryTextColor} 40%, transparent 60%)`,
            },
          },
        },
      },
    },
  });
};

export default createAppTheme;
