import { useMemo } from 'react';
import {
  BookingStep,
  BookingStepEnum,
} from '../../components/BookingWidget/bookingSteps.interface';

const summaryVisibleFor = [
  'QuotationInformationForm',
  'QuotationProposals',
  'QuotationRecapDeclarations',
];

const useSummaryVisible = (activeStep: BookingStep): boolean =>
  useMemo(
    () =>
      activeStep &&
      summaryVisibleFor.includes(activeStep[BookingStepEnum.Component]),
    [activeStep],
  );

export default useSummaryVisible;
