import { jwtDecode, JwtPayload } from 'jwt-decode';

interface CustomJwtPayload extends JwtPayload {
  source?: string;
  email?: string;
  iss?: string;
}

const getToken = (): CustomJwtPayload | null => {
  const token: string | null =
    localStorage.getItem('token') ?? localStorage.getItem('anonymousToken');
  return token ? jwtDecode(token) : null;
};

const isAuthCustomer = (): boolean => {
  const token: CustomJwtPayload | null = getToken();
  if (!token) {
    return false;
  }
  return token.iss?.includes('cognito') ?? false;
};

const isAnonCustomer = (): boolean => {
  const token: CustomJwtPayload | null = getToken();
  if (!token) {
    return false;
  }
  return token?.source === 'CM';
};

const isAgent = (): boolean => {
  const token = getToken();
  if (!token) {
    return false;
  }
  return !isAuthCustomer() && !isAnonCustomer();
};

export { isAuthCustomer, isAnonCustomer, isAgent, getToken };
