import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './translations/en.json';
import esTranslations from './translations/es.json';
import frTranslations from './translations/fr.json';
import { isAgent } from './shared/helpers/auth.helper';

export const getLanguage = (): string => {
  if (isAgent()) {
    return 'en';
  }
  const userLanguage = navigator.language.split(/[-_]/)[0];
  switch (userLanguage) {
    case 'en':
      return 'en';
    default:
      return 'en';
  }
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations,
    },
    es: {
      translation: esTranslations,
    },
    fr: {
      translation: frTranslations,
    },
  },
  lng: getLanguage(),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
