import React, { createContext, useMemo, useState } from 'react';
import { BookingDataResponse } from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';
import { BookingDataContextState } from './bookingDataContext.types';

const contextDefaultValues: BookingDataContextState = {
  bookingDataResponse: null,
  updateBookingData: (bookingDataResponse: BookingDataResponse): void =>
    undefined,
};
export const BookingDataContext =
  createContext<BookingDataContextState>(contextDefaultValues);

export const BookingDataContextProvider: React.FC<
  React.PropsWithChildren<object>
> = ({ children }) => {
  const [bookingDataResponse, setBookingData] =
    useState<BookingDataResponse | null>(
      contextDefaultValues.bookingDataResponse,
    );

  const handleUpdate = (bookingData: BookingDataResponse): void => {
    setBookingData(bookingData);
  };

  const bookingData: BookingDataContextState = useMemo(
    () => ({ bookingDataResponse, updateBookingData: handleUpdate }),
    [bookingDataResponse],
  );

  return (
    <BookingDataContext.Provider value={bookingData}>
      {children}
    </BookingDataContext.Provider>
  );
};
