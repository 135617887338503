export const AddonsPossibleValues = [
  'WINTERSPORTS',
  'BUSINESS',
  'GOLF',
  'TRAVELDISRUPTION',
];

export const OptionsPossibleValues = [
  'EXCESSWAIVER',
  'STANDARDEXCESS',
  'DOUBLEEXCESS',
];
