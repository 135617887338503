import { useContext, useCallback } from 'react';
import { BookingConfigContext } from '../../context/bookingConfigContext/bookingConfigContext';
import { BookingStepsContextState } from '../../context/bookingContext/bookingContext.types';
import { BookingQuoteContext } from '../../context/quoteContext/quoteContext';
import { getLanguage } from '../../i18n';
import useApi from '../services/api.service';
import { BookingQuote } from '../../components/BookingWidget/BookingQuoteManagment/bookingQuote.interface';
import parseDataToQuote from '../../components/BookingWidget/BookingQuoteManagment/bookingQuoteManagment.mapper';
import {
  BookingStepValueEnum,
  BookingConfigEnum,
  BookingSubStepValueEnum,
  BookingSubStepOptionEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import { SendQuoteHook } from '../interfaces/useSendQuoteEmail.interface';

const useSendQuoteEmail = (): SendQuoteHook => {
  const { bookingQuoteResponse } = useContext(BookingQuoteContext);
  const { bookingConfigData } = useContext(BookingConfigContext);

  const API = useApi(bookingConfigData);

  const prepareUrl = (bookingSteps: BookingStepsContextState): string => {
    const { cm360Endpoint, psPlatform } =
      bookingConfigData[BookingConfigEnum.DataCenter];
    const tripDetails =
      bookingSteps[BookingStepValueEnum.QuotationForm][
        BookingSubStepValueEnum.TripDetails
      ][BookingSubStepOptionEnum.Value];
    const apiCM360Url: string = cm360Endpoint;
    return `${apiCM360Url}/ui-proxy/ws-partners/api/documents/platforms/${psPlatform}/catalogs/${tripDetails}/products?locale=${getLanguage()}`;
  };

  const preparePayload = (
    email: string,
    bookingSteps: BookingStepsContextState,
    quoteId: string,
  ): BookingQuote =>
    parseDataToQuote(bookingSteps, bookingConfigData, {
      email,
      quoteId,
    });
  const sendQuote = useCallback(
    async (
      email: string,
      bookingSteps: BookingStepsContextState,
      quoteId: string,
    ) => {
      const { psClient } = bookingConfigData[BookingConfigEnum.DataCenter];
      const payload = preparePayload(email, bookingSteps, quoteId);
      const url = prepareUrl(bookingSteps);
      return API.post(url, payload, {
        headers: {
          'Client-Id': psClient,
        },
      });
    },
    [API, bookingConfigData, bookingQuoteResponse, preparePayload, prepareUrl],
  );

  return {
    sendQuote,
  };
};

export default useSendQuoteEmail;
