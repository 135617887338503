import {
  BookingConfig,
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../BookingWidget/bookingSteps.interface';
import {
  BookingStepsContextState,
  BookingSubStepsQuotationForm,
} from '../../../context/bookingContext/bookingContext.types';
import getTravellersAge from '../../../shared/helpers/getTravellersAge';
import getInsurancePayload from '../../../shared/helpers/getInsurancePayload.helper';

const getPayloadForQuotationProposal = (
  bookingSteps: BookingStepsContextState,
  bookingConfigData: BookingConfig,
): string => {
  const quotationForm: BookingSubStepsQuotationForm =
    bookingSteps[BookingStepValueEnum.QuotationForm];
  const params = {
    insurance: getInsurancePayload(bookingSteps, bookingConfigData),
    promotion: {
      promotionCode: quotationForm[BookingSubStepValueEnum.PromoCode],
    },
    subscriber: {
      preferredLanguage: localStorage.getItem('language') || '',
      // @TODO only for FCTG tests
      countryCode: 'au',
    },
    beneficiaries: getTravellersAge(
      quotationForm[BookingSubStepValueEnum.TravellersAge],
    ),
  };
  return JSON.stringify(params);
};

const stringToArrayWithOptions = (input: string): (string | null)[] => {
  const result: (string | null)[] = input.split('|').map((value) => {
    if (value === '') {
      return null;
    }
    return value;
  });

  return result;
};

const arrayWithOptionsToString = (input: { [key: number]: string }): string => {
  const values = Object.values(input);
  return values.map((v) => (v === '|' ? '' : v)).join('|');
};

export {
  getPayloadForQuotationProposal,
  stringToArrayWithOptions,
  arrayWithOptionsToString,
};
