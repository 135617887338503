import { BookingSubStepOption } from '../../components/BookingWidget/bookingSteps.interface';

export const getDefaultValuesForOptions2 = (
  addons: BookingSubStepOption[],
): string => '|'.repeat(addons.length - 1);

export const getDefaultValuesForAddons2 = (
  options: BookingSubStepOption[],
): { [key: string]: boolean | undefined } => {
  const result: { [key: string]: boolean | undefined } = {};
  options.forEach((o) => {
    result[o.value] = o.isDefault ? true : undefined;
  });
  return result;
};

export const getDefaultValuesForAddonsAndOptions = (
  options: BookingSubStepOption[],
): { [key: string]: boolean | undefined } => {
  const result: { [key: string]: boolean | undefined } = {};
  options.forEach((o) => {
    result[o.value] = o.isDefault ? true : undefined;
  });
  return result;
};
