const SingleTripCatalogs: string[] = ['B2C-AUS-ST.ESHOP'];
const CruiseTripCatalogs: string[] = ['B2C-AUS-CRUISE.ESHOP'];

export const getCatalogLabel = (catalogId: string): string => {
  if (SingleTripCatalogs.includes(catalogId)) {
    return 'oneTrip';
  }
  if (CruiseTripCatalogs.includes(catalogId)) {
    return 'cruise';
  }
  return '';
};

export default getCatalogLabel;
