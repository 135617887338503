class ErrorLoggingService {
  private static instance: ErrorLoggingService;

  private readonly isDevelopmentEnvironment: boolean;

  constructor() {
    this.isDevelopmentEnvironment =
      localStorage.getItem('debugMode') === 'true';
  }

  static getInstance(): ErrorLoggingService {
    if (!ErrorLoggingService.instance) {
      ErrorLoggingService.instance = new ErrorLoggingService();
    }
    return ErrorLoggingService.instance;
  }

  log(message: string, error?: any): void {
    if (this.isDevelopmentEnvironment) {
      console.log('Error Message:', message);
      if (error) {
        console.error('Error Details:', error);
      }
    }
  }
}

export default ErrorLoggingService;
