import { CircularProgress } from '@mui/material';
import React from 'react';
import './SpinnerStatic.scss';

const SpinnerStatic: React.FC = () => (
  <div className="spinner-static">
    <CircularProgress />
  </div>
);
export default SpinnerStatic;
