export enum BookingQuoteResponseQuoteProductPriceEnum {
  Currency = 'currency',
  Tax = 'tax',
  GrossPremium = 'grossPremium',
  NetPrice = 'netPrice',
  Commission = 'commission',
  Promotion = 'promotion',
  GrossPremiumBeforePromotion = 'grossPremiumBeforePromotion',
}

export interface BookingQuoteResponseQuoteProductPrice {
  [BookingQuoteResponseQuoteProductPriceEnum.Currency]: string;
  [BookingQuoteResponseQuoteProductPriceEnum.Tax]: number;
  [BookingQuoteResponseQuoteProductPriceEnum.GrossPremium]: number;
  [BookingQuoteResponseQuoteProductPriceEnum.NetPrice]: number;
  [BookingQuoteResponseQuoteProductPriceEnum.Commission]: number;
  [BookingQuoteResponseQuoteProductPriceEnum.Promotion]: boolean;
  [BookingQuoteResponseQuoteProductPriceEnum.GrossPremiumBeforePromotion]: number;
}

export enum BookingQuoteResponseQuoteProductEnum {
  Price = 'price',
  Code = 'code',
}

export interface BookingQuoteResponseQuoteProduct {
  [BookingQuoteResponseQuoteProductEnum.Price]: BookingQuoteResponseQuoteProductPrice;
  [BookingQuoteResponseQuoteProductEnum.Code]: string;
}

export enum BookingQuoteResponseQuoteEnum {
  QuoteId = 'quoteId',
  QuotationStep = 'quotationStep',
  Product = 'product',
}

export interface BookingQuoteResponseQuote {
  [BookingQuoteResponseQuoteEnum.QuoteId]: string;
  [BookingQuoteResponseQuoteEnum.QuotationStep]: string;
  [BookingQuoteResponseQuoteEnum.Product]: BookingQuoteResponseQuoteProduct;
}

export enum BookingQuoteResponseEnum {
  RequestId = 'requestId',
  Quotes = 'quotes',
  AccessToken = 'accessToken',
}

export interface BookingQuoteResponse {
  [BookingQuoteResponseEnum.RequestId]: string;
  [BookingQuoteResponseEnum.AccessToken]: string;
  [BookingQuoteResponseEnum.Quotes]: BookingQuoteResponseQuote[];
}
