import React, { createContext, useMemo, useState } from 'react';
import {
  LoadingContextProps,
  LoadingProviderProps,
} from './loadingContext.interface';

export const LoadingContext = createContext<LoadingContextProps>({
  isLoading: false,
  setIsLoading: () => undefined,
});

export const LoadingProvider: React.FC<LoadingProviderProps> = ({
  children,
}: LoadingProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const loadingContextValue: LoadingContextProps = useMemo(
    () => ({ isLoading, setIsLoading }),
    [isLoading],
  );
  return (
    <LoadingContext.Provider value={loadingContextValue}>
      {children}
    </LoadingContext.Provider>
  );
};
