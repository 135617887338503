enum BookingStyleColorsEnum {
  BannerBgColor = 'banner-bg-color',
  Primary = 'primary',
  TextPrimary = 'text-primary',
  DarkPrimary = 'dark-primary',
  DarkMiddle = 'dark-middle',
  DarkSecondary = 'dark-secondary',
  LightPrimary = 'light-primary',
  LightSecondary = 'light-secondary',
  ContrastPrimary = 'contrast-primary',
  ContrastSecondary = 'contrast-secondary',
  SelectedBorder = 'selected-border',
  ProductPrice = 'product-price',
}

interface BookingStyleColors {
  [BookingStyleColorsEnum.BannerBgColor]: string;
  [BookingStyleColorsEnum.Primary]: string;
  [BookingStyleColorsEnum.TextPrimary]: string;
  [BookingStyleColorsEnum.DarkPrimary]: string;
  [BookingStyleColorsEnum.DarkMiddle]: string;
  [BookingStyleColorsEnum.DarkSecondary]: string;
  [BookingStyleColorsEnum.LightPrimary]: string;
  [BookingStyleColorsEnum.LightSecondary]: string;
  [BookingStyleColorsEnum.SelectedBorder]: string;
}

enum BookingStylesEnum {
  Id = 'id',
  Styles = 'styles',
  Colors = 'colors',
}

interface BookingStyles {
  [BookingStylesEnum.Id]: string;
  [BookingStylesEnum.Styles]: {
    [BookingStylesEnum.Colors]: BookingStyleColors;
  };
}

export {
  BookingStyleColors,
  BookingStyles,
  BookingStylesEnum,
  BookingStyleColorsEnum,
};
