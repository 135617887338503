import { CircularProgress } from '@mui/material';
import React, { useContext } from 'react';
import { LoadingContext } from '../../../context/loadingContext/loadingContext';
import './Spinner.scss';

const Spinner: React.FC = () => {
  const { isLoading } = useContext(LoadingContext);
  return isLoading ? (
    <div className="loading">
      <CircularProgress />
    </div>
  ) : null;
};

export default Spinner;
