import { useContext, useEffect, useState } from 'react';
import { BookingConfig } from '../bookingSteps.interface';
import { BookingConfigContext } from '../../../context/bookingConfigContext/bookingConfigContext';
import { BookingContext } from '../../../context/bookingContext/bookingContext';
import { getBootstrapParamsFromUrl } from '../../../shared/helpers/appBootstrap.helpers';
import useApi from '../../../shared/services/api.service';
import { getLanguage } from '../../../i18n';
import getUrlSearchParam from '../../../shared/helpers/urlSearchParams.helper';

interface FetchResult {
  bookingConfig: BookingConfig;
  error: string | null;
}

function delay(ms: number): Promise<unknown> {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const useFetchBooking = <T>(params: string, setIsLoading: any): FetchResult => {
  const [bookingConfig, setData] = useState<BookingConfig>(null!);
  const [error, setError] = useState<string | null>(null);
  const { bookingSteps, update, updateDefault } = useContext(BookingContext);
  const { bookingConfigData, updateConfig } = useContext(BookingConfigContext);
  const urlParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );
  const brand: string | null = getUrlSearchParam('brand', urlParams);
  const API = useApi(bookingConfigData);

  useEffect((): void => {
    const fetchData = async (): Promise<void> => {
      try {
        setIsLoading(true);
        const selectedBrand = brand ? brand.toLowerCase() : 'tick';
        const userLanguage = getLanguage();
        const timestamp = Date.now();
        const configUrl = `/config/config_booking_${selectedBrand}_${userLanguage}.json?timestamp=${timestamp}`;
        const response = await fetch(configUrl);
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }
        const fetchedData = await response.json();
        updateDefault(fetchedData[0]);
        setData(fetchedData[0]);
        setError(null!);
        updateConfig(fetchedData[0]);
        const bootstrapBookingData = getBootstrapParamsFromUrl(
          fetchedData[0],
          bookingSteps,
        );
        if (bootstrapBookingData) {
          update(bootstrapBookingData, null!);
        }
      } catch (errorResponse) {
        if (errorResponse instanceof Error) {
          setError(errorResponse.message);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [params, setIsLoading]);

  return { bookingConfig, error };
};

export default useFetchBooking;
