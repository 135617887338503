const getFontName = (brand: string): string => {
  switch (brand) {
    case 'igo':
      return 'Slussen-Bold';
    case 'tick':
      return 'Verdana';
    default:
      return 'Roboto';
  }
};

export default getFontName;
