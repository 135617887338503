import React, { createContext, useMemo, useState } from 'react';
import {
  AppConfiguration,
  AppConfigurationContextState,
} from './appConfigurationContext.types';

const contextDefaultValues: AppConfigurationContextState = {
  appConfiguration: null,
  updateConfiguration: (appConfiguration: AppConfiguration): void => undefined,
};
export const AppConfigurationContext =
  createContext<AppConfigurationContextState>(contextDefaultValues);

export const AppConfigurationContextProvider: React.FC<
  React.PropsWithChildren<object>
> = ({ children }) => {
  const [appConfiguration, setAppConfig] = useState<AppConfiguration | null>(
    contextDefaultValues.appConfiguration,
  );

  const handleUpdate = (appConfigurationData: AppConfiguration): void => {
    setAppConfig(appConfigurationData);
  };

  const appConfig: AppConfigurationContextState = useMemo(
    () => ({ appConfiguration, updateConfiguration: handleUpdate }),
    [appConfiguration],
  );

  return (
    <AppConfigurationContext.Provider value={appConfig}>
      {children}
    </AppConfigurationContext.Provider>
  );
};
