import { useState } from 'react';
import { AxiosResponse } from 'axios';
import {
  BookingConfig,
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepOptionEnum,
  BookingSubStepValueEnum,
} from '../BookingWidget/bookingSteps.interface';
import { BookingStepsContextState } from '../../context/bookingContext/bookingContext.types';
import { getPayloadForQuotationProposal } from './components/quotationProposal.helper';
import useApi from '../../shared/services/api.service';

interface FetchResult<T> {
  data: T | null;
  loading: boolean;
  error: string | null;
  fetchData: () => Promise<void>;
}

export interface ProposalsResponse {
  requestId: string;
  version: string;
  errors: boolean;
  proposals: Proposal[];
}

export interface Proposal {
  error: boolean;
  products: Product[];
  totalPrice: Price;
}

export enum ProductEnum {
  Code = 'code',
  BeneficiaryIndex = 'beneficiaryIndex',
  Description = 'description',
  Selected = 'selected',
  Mandatory = 'mandatory',
  Price = 'price',
  Covers = 'covers',
  AdditionalValues = 'additionalValues',
  Label = 'label',
  ProductDocuments = 'productDocuments',
}

export interface Product {
  [ProductEnum.Code]: string;
  [ProductEnum.BeneficiaryIndex]: number;
  [ProductEnum.Description]: string;
  [ProductEnum.Selected]: boolean;
  [ProductEnum.Mandatory]: boolean;
  [ProductEnum.Price]: Price;
  [ProductEnum.Covers]: ProductCovers[];
  [ProductEnum.AdditionalValues]: { [key: string]: string };
  [ProductEnum.Label]: {
    title: string;
    content: string;
  };
  [ProductEnum.ProductDocuments]: ProductDocument[];
}

export enum ProductDocumentEnum {
  Key = 'key',
  Label = 'label',
  Link = 'link',
}

export interface ProductDocument {
  [ProductDocumentEnum.Key]: string;
  [ProductDocumentEnum.Label]: string;
  [ProductDocumentEnum.Link]: string;
}

export interface ProductCovers {
  code: string;
  productCode?: string;
  description: string;
  limits: string;
  label: Label;
}

export interface Label {
  title: string;
  content: string;
}

export interface Price {
  currency: string;
  tax: number;
  grossPremium: number;
  netPrice: number;
  commission: number;
  promotion: boolean;
  grossPremiumBeforePromotion: number;
  markUp: number;
}

const useFetchProposals = (
  bookingSteps: BookingStepsContextState,
  bookingConfigData: BookingConfig,
  showNotification: any,
): FetchResult<ProposalsResponse> => {
  const [data, setData] = useState<ProposalsResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const apiCM360Url: string =
    bookingConfigData[BookingConfigEnum.DataCenter].cm360Endpoint;
  const payload: string = getPayloadForQuotationProposal(
    bookingSteps,
    bookingConfigData,
  );
  const API = useApi(bookingConfigData);

  const fetchData = async (): Promise<void> => {
    setLoading(true);
    try {
      const url = `${apiCM360Url}/ui-proxy/ws-partners/api/platforms/${
        bookingConfigData[BookingConfigEnum.DataCenter].psPlatform
      }/catalogs/${
        bookingSteps[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.TripDetails
        ][BookingSubStepOptionEnum.Value]
      }/products`;
      const response: AxiosResponse = await API.post(url, payload, {
        headers: {
          'Client-Id': bookingConfigData[BookingConfigEnum.DataCenter].psClient,
        },
      });

      if (response.status !== 200) {
        showNotification('fetchingData', 'error', false);
        throw new Error(`Error fetching data: ${response.statusText}`);
      }
      const fetchedData = response.data;
      setData(fetchedData);
    } catch (errorResponse) {
      showNotification('fetchingData', 'error', false);
    } finally {
      setLoading(false);
    }
  };

  return { data, fetchData, loading, error };
};

export default useFetchProposals;
