enum InsuranceEnum {
  BookingDate = 'bookingDate',
  StartDate = 'startDate',
  EndDate = 'endDate',
  InsuranceData = 'insuranceData',
}

enum InsuranceDataEnum {
  GeographicalZone = 'geographicalZone',
  State = 'state',
  Brand = 'brand',
  InsuranceAddons = 'insuranceAddons',
  InsuranceOptions = 'insuranceOptions',
  MedicalScore = 'medicalScore',
  IsFamilyOrCouple = 'isFamilyOrCouple',
  PartnerCode = 'partnerCode',
  MaxTripDuration = 'maxTripDuration',
  AgentSales = 'agentSales',
  CreditAmount = 'creditAmount',
  DiscretionaryDiscountAmount = 'dd_amount',
  DiscretionaryDiscountPercentage = 'dd_rate',
  DiscretionaryDiscountReason = 'dd_reason',
}

interface InsuranceData {
  [InsuranceDataEnum.GeographicalZone]: string;
  [InsuranceDataEnum.State]: string;
  [InsuranceDataEnum.Brand]: string;
  [InsuranceDataEnum.InsuranceAddons]: string;
  [InsuranceDataEnum.InsuranceOptions]: string;
  [InsuranceDataEnum.IsFamilyOrCouple]: string;
  [InsuranceDataEnum.PartnerCode]: string;
  [InsuranceDataEnum.MaxTripDuration]?: number;
  [InsuranceDataEnum.AgentSales]?: string;
  [InsuranceDataEnum.CreditAmount]?: string;
}
interface InsurancePayload {
  [InsuranceEnum.BookingDate]?: string;
  [InsuranceEnum.StartDate]: string;
  [InsuranceEnum.EndDate]: string;
  [InsuranceEnum.InsuranceData]: InsuranceData;
}

export { InsurancePayload, InsuranceEnum, InsuranceData, InsuranceDataEnum };
