import { BookingDataEnum } from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';

enum AppBootstrapEnum {
  Companion1 = 'companion1',
  Companion2 = 'companion2',
  Companion3 = 'companion3',
  Companion4 = 'companion4',
  Companion5 = 'companion5',
  Companion6 = 'companion6',
  Companion7 = 'companion7',
  Companion8 = 'companion8',
  Companion9 = 'companion9',
  Companion10 = 'companion10',
  Partner = 'partner',
  AggregatorId = 'aggregatorId',
  AffiliateId = 'affiliateId',
  ExternalBookingId = 'externalBookingId',
  ExternalBacklink = 'externalBacklink',
  UtmSource = 'utm_source',
}

const PossibleValuesInUrl = [
  BookingDataEnum.CurrentStep,
  BookingDataEnum.Catalog,
  BookingDataEnum.Destination,
  BookingDataEnum.StartDate,
  BookingDataEnum.EndDate,
  BookingDataEnum.TravellersType,
  BookingDataEnum.PromoCode,
  BookingDataEnum.Product,
  BookingDataEnum.Addons,
  BookingDataEnum.Options,
  BookingDataEnum.MaxTripDuration,
  BookingDataEnum.QuoteTransactionId,
  AppBootstrapEnum.Companion1,
  AppBootstrapEnum.Companion2,
  AppBootstrapEnum.Companion3,
  AppBootstrapEnum.Companion4,
  AppBootstrapEnum.Companion5,
  AppBootstrapEnum.Companion6,
  AppBootstrapEnum.Companion7,
  AppBootstrapEnum.Companion8,
  AppBootstrapEnum.Companion9,
  AppBootstrapEnum.Companion10,
  AppBootstrapEnum.UtmSource,
  AppBootstrapEnum.Partner,
  AppBootstrapEnum.AggregatorId,
  AppBootstrapEnum.AffiliateId,
  AppBootstrapEnum.ExternalBookingId,
  AppBootstrapEnum.ExternalBacklink,
];

export { AppBootstrapEnum, PossibleValuesInUrl };
