export enum BookingQuoteProductEnum {
  ProductGroup = 'productGroup',
  Code = 'code',
}

export interface BookingQuoteProduct {
  [BookingQuoteProductEnum.ProductGroup]: {
    [BookingQuoteProductEnum.Code]: string;
  }[];
}

export enum BookingQuoteInsuranceEnum {
  BookingDate = 'bookingDate',
  StartDate = 'startDate',
  EndDate = 'endDate',
  PromotionCode = 'promotionCode',
  AmountToInsure = 'amountToInsure',
  InsuranceData = 'insuranceData',
}

export interface BookingQuoteInsurance {
  [BookingQuoteInsuranceEnum.BookingDate]: string;
  [BookingQuoteInsuranceEnum.StartDate]: string;
  [BookingQuoteInsuranceEnum.EndDate]: string;
  [BookingQuoteInsuranceEnum.InsuranceData]: BookingQuoteInsuranceData;
}

export enum BookingQuoteInsuranceDataEnum {
  Brand = 'brand',
  GeographicalZone = 'geographicalZone',
  InsuranceOptions = 'insuranceOptions',
  InsuranceAddons = 'insuranceAddons',
  MedicalScore = 'medicalScore',
  QuoteId = 'quoteId',
  IsFamilyOrCouple = 'isFamilyOrCouple',
  PartnerCode = 'partnerCode',
  MaxTripDuration = 'maxTripDuration',
  AgentSales = 'agentSales',
  DiscretionaryDiscountAmount = 'dd_amount',
  DiscretionaryDiscountPercentage = 'dd_rate',
  DiscretionaryDiscountReason = 'dd_reason',
}

export interface BookingQuoteInsuranceData {
  [BookingQuoteInsuranceDataEnum.Brand]: string;
  [BookingQuoteInsuranceDataEnum.IsFamilyOrCouple]: string;
  [BookingQuoteInsuranceDataEnum.GeographicalZone]: string;
  [BookingQuoteInsuranceDataEnum.InsuranceOptions]: string;
  [BookingQuoteInsuranceDataEnum.InsuranceAddons]: string;
  [BookingQuoteInsuranceDataEnum.QuoteId]?: string;
  [BookingQuoteInsuranceDataEnum.PartnerCode]?: string;
  [BookingQuoteInsuranceDataEnum.AgentSales]?: string;
  [BookingQuoteInsuranceDataEnum.DiscretionaryDiscountAmount]?: number;
  [BookingQuoteInsuranceDataEnum.DiscretionaryDiscountPercentage]?: number;
  [BookingQuoteInsuranceDataEnum.DiscretionaryDiscountReason]?: string;
}

export enum BookingQuoteSubscriptionEnum {
  Price = 'price',
}

export interface BookingQuoteSubscription {
  [BookingQuoteSubscriptionEnum.Price]: string;
}

export enum BookingQuoteSubscriberEnum {
  PreferredLanguage = 'preferredLanguage',
  CountryCode = 'countryCode',
  Civility = 'civility',
  Birthdate = 'birthdate',
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  MobilePhone = 'mobilePhone',
  Phone = 'phone',
  Address1 = 'address1',
  Address2 = 'address2',
  PostalCode = 'postalCode',
  City = 'city',
}

export interface BookingQuoteSubscriber {
  [BookingQuoteSubscriberEnum.PreferredLanguage]: string;
  [BookingQuoteSubscriberEnum.CountryCode]: string;
  [BookingQuoteSubscriberEnum.Civility]?: string;
  [BookingQuoteSubscriberEnum.Birthdate]?: string;
  [BookingQuoteSubscriberEnum.FirstName]?: string;
  [BookingQuoteSubscriberEnum.LastName]?: string;
  [BookingQuoteSubscriberEnum.Email]?: string;
  [BookingQuoteSubscriberEnum.MobilePhone]?: string;
  [BookingQuoteSubscriberEnum.Phone]?: string;
  [BookingQuoteSubscriberEnum.Address1]?: string;
  [BookingQuoteSubscriberEnum.Address2]?: string;
  [BookingQuoteSubscriberEnum.PostalCode]?: string;
  [BookingQuoteSubscriberEnum.City]?: string;
}

export enum BookingQuoteBeneficiariesEnum {
  BirthDate = 'birthDate',
  Firstname = 'firstName',
  Lastname = 'lastName',
  Subscriber = 'subscriber',
  IdNumber = 'idNumber',
}

export interface BookingQuoteBeneficiaries {
  [BookingQuoteBeneficiariesEnum.BirthDate]: string;
  [BookingQuoteBeneficiariesEnum.IdNumber]?: number;
  [BookingQuoteBeneficiariesEnum.Firstname]?: string;
  [BookingQuoteBeneficiariesEnum.Lastname]?: string;
  [BookingQuoteBeneficiariesEnum.Subscriber]?: boolean;
}

export enum BookingQuoteEnum {
  QuotationStep = 'quotationStep',
  Product = 'product',
  Insurance = 'insurance',
  Subscription = 'subscription',
  Subscriber = 'subscriber',
  Beneficiaries = 'beneficiaries',
  Declarations = 'declarations',
  Promotion = 'promotion',
  Email = 'email',
}

export interface BookingQuote {
  [BookingQuoteEnum.QuotationStep]?: string;
  [BookingQuoteEnum.Product]?: BookingQuoteProduct;
  [BookingQuoteEnum.Insurance]: BookingQuoteInsurance;
  [BookingQuoteEnum.Subscription]?: BookingQuoteSubscription;
  [BookingQuoteEnum.Subscriber]: BookingQuoteSubscriber;
  [BookingQuoteEnum.Beneficiaries]: BookingQuoteBeneficiaries[];
  [BookingQuoteEnum.Promotion]: {
    [BookingQuoteInsuranceEnum.PromotionCode]: string;
  };
}
