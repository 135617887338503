import { useEffect, useState } from 'react';
import { BookingStyles } from '../interfaces/bookingStyles.interface';
import ErrorLoggingService from './errorlogging.service';
import getUrlSearchParam from '../helpers/urlSearchParams.helper';

const useFetchBookingStyles = <T>(): any => {
  const [styleConfig, setStyleData] = useState<BookingStyles>(null!);
  const urlParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );
  const brand: string | null = getUrlSearchParam('brand', urlParams);

  useEffect((): void => {
    if (!brand) {
      return;
    }
    const fetchData = async (): Promise<void> => {
      const errorService = ErrorLoggingService.getInstance();
      try {
        const selectedBrand = brand ? brand.toLowerCase() : 'tick';
        const timestamp = Date.now();
        const configUrl = `/config/config_styles_${selectedBrand}.json?timestamp=${timestamp}`;
        const response = await fetch(configUrl);
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }
        const fetchedData = await response.json();
        setStyleData(fetchedData[0]);
      } catch (errorResponse) {
        errorService.log('Error loading styles');
      }
    };
    fetchData();
  }, []);

  return { styleConfig };
};

export default useFetchBookingStyles;
