enum BookingDataEnum {
  Id = 'id',
  CurrentStep = 'currentStep',
  Catalog = 'catalog',
  Partner = 'partner',
  AggregatorId = 'aggregatorId',
  AffiliateId = 'affiliateId',
  Region = 'region',
  Destination = 'destination',
  CountryCode = 'countryCode',
  TravellersType = 'travellersType',
  Product = 'product',
  Proxy = 'proxy',
  ExpireDate = 'expireDate',
  StartDate = 'startDate',
  EndDate = 'endDate',
  PromoCode = 'promoCode',
  IsWinterSport = 'isWinterSport',
  ProductName = 'productName',
  Addons = 'addons',
  Options = 'options',
  DiscountAmount = 'discountAmount',
  DiscountPercentage = 'discountPercentage',
  DiscountApprovedBy = 'discountApprovedBy',
  DiscountReason = 'discountReason',
  DiscountRequestedAt = 'discountRequestedAt',
  IsDiscountApproved = 'isDiscountApproved',
  MaxTripDuration = 'maxTripDuration',
  ExternalBookingId = 'externalBookingId',
  ExternalBacklink = 'externalBacklink',
  QuoteTransactionId = 'quoteTransactionId',
  Aggregator = 'aggregator',
}

enum ProxyEnum {
  Id = 'id',
  QuoteId = 'quoteId',
  PolicyId = 'policyId',
  Amount = 'amount',
  BusinessUnitCode = 'businessUnitCode',
  BrandCode = 'brandCode',
}

enum ConsentEnum {
  Id = 'id',
  CreatedDate = 'createdDate',
  ExpireDate = 'expireDate',
  Usages = 'usages',
}

enum ConsentUsageEnum {
  Id = 'id',
  Type = 'type',
  Version = 'version',
  IsAgentExclusive = 'isAgentExclusive',
}

enum AmountEnum {
  Amount = 'amount',
  Currency = 'currency',
}

enum CustomerEnum {
  EmailAddress = 'emailAddress',
  Title = 'title',
  FirstName = 'firstName',
  LastName = 'lastName',
  DateOfBirth = 'dateOfBirth',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  AddressLine3 = 'addressLine3',
  AddressLine4 = 'addressLine4',
  PostalCode = 'postalCode',
  CountryCode = 'countryCode',
  PhoneNumber1 = 'phoneNumber1',
  PhoneNumber2 = 'phoneNumber2',
  Username = 'username',
  AccessToken = 'accessToken',
}

enum CompanionEnum {
  Id = 'id',
  IsCustomer = 'isCustomer',
  FirstName = 'firstName',
  LastName = 'lastName',
  DateOfBirth = 'dateOfBirth',
  Age = 'age',
}

enum BookingManagementEnum {
  BookingData = 'bookingData',
  Customer = 'customer',
  Companions = 'companions',
  Consents = 'consents',
}

interface BookingData {
  [BookingDataEnum.CurrentStep]: string;
  [BookingDataEnum.Catalog]: string;
  [BookingDataEnum.Region]: string;
  [BookingDataEnum.Destination]: string;
  [BookingDataEnum.CountryCode]: string;
  [BookingDataEnum.Product]: string;
  [BookingDataEnum.StartDate]: string;
  [BookingDataEnum.EndDate]: string;
  [BookingDataEnum.TravellersType]: string;
  [BookingDataEnum.ProductName]: string;
  [BookingDataEnum.Addons]: { [key: string]: boolean | undefined };
  [BookingDataEnum.Options]: { [key: string]: boolean | undefined };
  [BookingDataEnum.PromoCode]?: string;
  [BookingDataEnum.IsWinterSport]?: boolean;
  [BookingDataEnum.Proxy]?: Proxy;
  [BookingDataEnum.ExpireDate]?: Proxy;
  [BookingDataEnum.DiscountAmount]?: string;
  [BookingDataEnum.DiscountPercentage]?: string;
  [BookingDataEnum.DiscountApprovedBy]?: string;
  [BookingDataEnum.DiscountReason]?: string;
  [BookingDataEnum.DiscountRequestedAt]?: string;
  [BookingDataEnum.IsDiscountApproved]?: boolean;
  [BookingDataEnum.Id]?: string;
  [BookingDataEnum.QuoteTransactionId]?: string;
  [BookingDataEnum.Aggregator]?: string;
}

interface BookingDataResponse extends BookingManagement {
  [BookingDataEnum.Id]: string;
}

interface Proxy {
  [ProxyEnum.Id]?: string;
  [ProxyEnum.QuoteId]: string;
  [ProxyEnum.PolicyId]: string;
  [ProxyEnum.Amount]: Amount;
  [ProxyEnum.BrandCode]: string;
  [ProxyEnum.BusinessUnitCode]: string;
}

interface Consent {
  [ConsentEnum.Id]?: number;
  [ConsentEnum.CreatedDate]?: string;
  [ConsentEnum.ExpireDate]?: string;
  [ConsentEnum.Usages]: ConsentUsage[];
}

interface ConsentUsage {
  [ConsentUsageEnum.Id]?: number;
  [ConsentUsageEnum.Type]: string;
  [ConsentUsageEnum.Version]: number;
  [ConsentUsageEnum.IsAgentExclusive]: boolean;
}

interface Amount {
  [AmountEnum.Amount]: number;
  [AmountEnum.Currency]: string;
}

interface Customer {
  [CustomerEnum.EmailAddress]?: string;
  [CustomerEnum.Title]?: string;
  [CustomerEnum.FirstName]?: string;
  [CustomerEnum.LastName]?: string;
  [CustomerEnum.DateOfBirth]: string;
  [CustomerEnum.AddressLine1]?: string;
  [CustomerEnum.AddressLine2]?: string;
  [CustomerEnum.AddressLine3]?: string;
  [CustomerEnum.AddressLine4]?: string;
  [CustomerEnum.PostalCode]?: string;
  [CustomerEnum.CountryCode]?: string;
  [CustomerEnum.PhoneNumber1]?: string;
  [CustomerEnum.PhoneNumber2]?: string;
  [CustomerEnum.Username]?: string;
  [CustomerEnum.AccessToken]?: string;
}

interface Companion {
  [CompanionEnum.Id]?: number;
  [CompanionEnum.DateOfBirth]: string;
  [CompanionEnum.Age]: number;
  [CompanionEnum.IsCustomer]?: boolean;
  [CompanionEnum.FirstName]?: string;
  [CompanionEnum.LastName]?: string;
}

interface BookingManagement {
  [BookingManagementEnum.BookingData]: BookingData;
  [BookingManagementEnum.Customer]?: Customer;
  [BookingManagementEnum.Companions]?: Companion[];
  [BookingManagementEnum.Consents]?: Consent[];
}

export {
  BookingManagement,
  BookingManagementEnum,
  BookingData,
  BookingDataEnum,
  Customer,
  CustomerEnum,
  Companion,
  CompanionEnum,
  Amount,
  AmountEnum,
  ProxyEnum,
  Consent,
  ConsentEnum,
  ConsentUsage,
  ConsentUsageEnum,
  BookingDataResponse,
};
