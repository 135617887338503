import {
  BookingConfig,
  BookingConfigEnum,
  BookingStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';

const getBookingStepIndex = (
  bookingConfig: BookingConfig,
  stepName: BookingStepValueEnum,
): number =>
  bookingConfig[BookingConfigEnum.BookingSteps].findIndex(
    (s) => s.keyName === stepName,
  );

export default getBookingStepIndex;
