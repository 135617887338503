import axios from 'axios';
import {
  BookingConfig,
  BookingConfigEnum,
} from '../../components/BookingWidget/bookingSteps.interface';

const useApi = (bookingConfigData: BookingConfig): any => {
  const apiCM360Url: string =
    bookingConfigData[BookingConfigEnum.DataCenter]?.cm360Endpoint;
  const apiCM360Key: string =
    bookingConfigData[BookingConfigEnum.DataCenter]?.cm360Key;
  const api = axios.create({
    baseURL: apiCM360Url,
  });
  api.interceptors.request.use(
    async (config) => {
      const modifiedConfig = { ...config };
      const token = localStorage.getItem('token');
      const anonymousToken = localStorage.getItem('anonymousToken') || null;

      if (!modifiedConfig.headers['X-Api-Key']) {
        modifiedConfig.headers['X-Api-Key'] = apiCM360Key;
      }

      modifiedConfig.headers['Content-Type'] = 'application/json';

      if (!modifiedConfig.headers.Authorization && (token || anonymousToken)) {
        modifiedConfig.headers.Authorization = token
          ? `Bearer ${token}`
          : `Bearer ${anonymousToken}`;
      }

      return modifiedConfig;
    },
    (error) => Promise.reject(error),
  );

  return api;
};

export default useApi;
