import { useContext, useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BookingConfigEnum } from '../../components/BookingWidget/bookingSteps.interface';
import { isAgent } from './auth.helper';
import { BookingConfigContext } from '../../context/bookingConfigContext/bookingConfigContext';

type UseCardUx = {
  isCardUX: boolean;
};

const useCardUX = (skipMobile: boolean): UseCardUx => {
  const { bookingConfigData } = useContext(BookingConfigContext);
  const isMobileView = useMediaQuery('(max-width:600px)');

  const isCardUX = useMemo(
    () =>
      bookingConfigData[BookingConfigEnum.Layout] === 'CARD_UX_WEB' ||
      (!skipMobile && isMobileView) ||
      isAgent(),
    [bookingConfigData, isMobileView],
  );

  return { isCardUX };
};

export default useCardUX;
