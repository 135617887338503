import {
  GtmEvent,
  GtmEventEcommerceActionField,
  GtmEventEcommerceActionFieldEnum,
  GtmEventEcommerceEnum,
  GtmEventEcommerceProduct,
  GtmEventEcommerceProductsEnum,
  GtmEventEnum,
} from '../interfaces/gtm.interface';
import { GTM_EVENT } from '../consts/gtm.const';
import { BookingData } from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';
import { Policy, PolicyResponse } from '../interfaces/policyResponse.interface';
import { TransactionResponse } from '../interfaces/transaction.interface';
import ErrorLoggingService from './errorlogging.service';

const pushGtmEvent = (event: GtmEvent): void => {
  if (window.parent) {
    window.parent.postMessage(
      {
        type: 'GTM_EVENT',
        data: event,
      },
      '*',
    );
  }
};

const prepareGtmEvent = (
  eventName: GTM_EVENT,
  bookingData: BookingData,
  policyResponse: PolicyResponse,
  transactionResponse: TransactionResponse,
): GtmEvent => {
  const policy: Policy = policyResponse.policies[0];
  const products: GtmEventEcommerceProduct[] = [];
  const product: GtmEventEcommerceProduct = {
    [GtmEventEcommerceProductsEnum.Id]: policy.productCode || '',
    [GtmEventEcommerceProductsEnum.Name]: policyResponse.version || '',
    [GtmEventEcommerceProductsEnum.Price]:
      policyResponse.totalPrice.grossPremium || undefined,
    [GtmEventEcommerceProductsEnum.Brand]:
      policy.additionalValues[0]?.data?.brand || '',
    [GtmEventEcommerceProductsEnum.Variant]:
      policy.additionalValues[0]?.data?.partner_code || '',
    [GtmEventEcommerceProductsEnum.Quantity]:
      policyResponse.policies.length || undefined,
    // [GtmEventEcommerceProductsEnum.Category]: '',
    // [GtmEventEcommerceProductsEnum.Position]: '',
  };

  products.push(product);

  const actionField: GtmEventEcommerceActionField = {
    [GtmEventEcommerceActionFieldEnum.Id]: policy.policyNumber || '',
    [GtmEventEcommerceActionFieldEnum.Revenue]:
      policyResponse.totalPrice.grossPremium || undefined,
    [GtmEventEcommerceActionFieldEnum.Tax]:
      policyResponse.totalPrice.tax || undefined,
    [GtmEventEcommerceActionFieldEnum.Affiliation]:
      policyResponse.policies[0].additionalValues[0]?.data?.partner_code || '',
    // [GtmEventEcommerceActionFieldEnum.Shipping]: '',
  };

  return {
    [GtmEventEnum.Event]: eventName,
    [GtmEventEnum.Currency]: policyResponse.totalPrice.currency || '',
    [GtmEventEnum.Ecommerce]: {
      [GtmEventEcommerceEnum.ActionField]: actionField,
      [GtmEventEcommerceEnum.Products]: products,
    },
  } as GtmEvent;
};

export const sendGTMEventToParent = (
  eventName: GTM_EVENT,
  bookingData: BookingData,
  policyResponse: PolicyResponse,
  transactionResponse: TransactionResponse,
): void => {
  const errorService: ErrorLoggingService = ErrorLoggingService.getInstance();
  try {
    const event: GtmEvent = prepareGtmEvent(
      eventName,
      bookingData,
      policyResponse,
      transactionResponse,
    );
    pushGtmEvent(event);
  } catch (error) {
    errorService.log('Error sending GTM event to parent', error);
  }
};

export const sendGTMEventPageChangeToParent = (
  eventName: GTM_EVENT,
  stepName: string,
): void => {
  const errorService: ErrorLoggingService = ErrorLoggingService.getInstance();
  try {
    const event: GtmEvent = {
      [GtmEventEnum.Event]: eventName,
      [GtmEventEnum.Value]: stepName,
    };
    pushGtmEvent(event);
  } catch (error) {
    errorService.log('Error sending GTM event to parent', error);
  }
};
