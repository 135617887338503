import { AxiosResponse } from 'axios';
import {
  BookingConfig,
  BookingConfigEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import { BookingStepsContextState } from '../../context/bookingContext/bookingContext.types';
import parseDataToQuote from '../../components/BookingWidget/BookingQuoteManagment/bookingQuoteManagment.mapper';
import useApi from './api.service';
import { BookingDataResponse } from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';
import { BookingQuoteResponse } from '../../components/BookingWidget/BookingQuoteManagment/bookingQuoteResponse.interface';

async function createQuote(
  apiProxyUrl: string,
  bookingSteps: BookingStepsContextState,
  bookingConfigData: BookingConfig,
  { agentEmail }: { agentEmail?: string } = {},
  bookingDataResponse: BookingDataResponse | null = null,
): Promise<BookingQuoteResponse> {
  /* eslint-disable react-hooks/rules-of-hooks */
  const API = useApi(bookingConfigData);
  const url = `${apiProxyUrl}/ui-proxy/ws-partners/api/platforms/${
    bookingConfigData[BookingConfigEnum.DataCenter].psPlatform
  }/catalogs/${bookingSteps.quotationForm.tripDetails.value}/quotes`;
  const payload = parseDataToQuote(
    bookingSteps,
    bookingConfigData,
    {
      agentEmail,
    },
    bookingDataResponse,
  );
  const response: AxiosResponse = await API.post(url, payload, {
    headers: {
      'Client-Id': bookingConfigData[BookingConfigEnum.DataCenter].psClient,
    },
  });
  return response.data;
}

export default createQuote;
