import dayjs, { Dayjs } from 'dayjs';
import { format, parseISO } from 'date-fns';

const parseDateDDMMYYYY = (dateString: string): Date => {
  const parts = dateString.split('/');
  const day = parseInt(parts[0], 10);
  // Subtract 1 from month because months are 0-indexed in JS Date
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);
  return new Date(year, month, day);
};

const parseStringToDate = (date: string, dateFormat: string): Date => {
  if (dateFormat === 'DD/MM/YYYY') {
    return parseDateDDMMYYYY(date);
  }
  return new Date(date);
};

export const addTimeToDate = (date: dayjs.Dayjs): dayjs.Dayjs => {
  const now = dayjs();
  return dayjs(date).hour(now.hour()).minute(now.minute()).second(now.second());
};

export const parseDateTimeStringToDate = (date: string): string => {
  const parsedDate = parseISO(date);
  return format(parsedDate, 'MM/dd/yyyy');
};

export const customParseFunction = (dateString: string): Dayjs => {
  const parts = dateString.split('/');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is zero-based
  const year = parseInt(parts[2], 10);

  return dayjs().set('year', year).set('month', month).set('date', day);
};

export default parseStringToDate;
