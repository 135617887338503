const getTravellersTypeValue = (travellersType: string): string => {
  if (travellersType === 'individual') {
    return '0';
  }
  if (travellersType === 'couple-or-family') {
    return '1';
  }
  return '';
};

export default getTravellersTypeValue;
