import { GetQuoteResponse } from '../../../shared/interfaces/getQuote.interface';
import { BookingQuoteResponse } from './bookingQuoteResponse.interface';

const mapToCreateQuoteResponse = (
  quoteId: string,
  quoteResult: GetQuoteResponse,
  accessToken: string | null,
): BookingQuoteResponse => {
  const quoteDetail = {
    quoteId,
    quotationStep: quoteResult.quote.quotationStep,
    product: {
      price: {
        currency: quoteResult.quote?.product.price.currency,
        tax: quoteResult.quote.product.price.tax,
        grossPremium: quoteResult.quote.product.price.grossPremium,
        netPrice: quoteResult.quote.product.price.netPrice,
        commission: 0, //! @TODO - currently not supported and hardcoded to 0. To fix if it will be supported.
        promotion: quoteResult.quote.product.price.promotion,
        grossPremiumBeforePromotion:
          quoteResult.quote.product.price.grossPremiumBeforePromotion,
      },
      code: quoteResult.quote.product.code,
    },
    creationDate: quoteResult.quote.creationDate,
    expirationDate: quoteResult.quote.expirationDate,
  };

  return {
    requestId: quoteResult.requestId,
    quotes: [quoteDetail],
    accessToken: accessToken || '',
  };
};

export default mapToCreateQuoteResponse;
