enum GtmEventEnum {
  Event = 'event',
  Value = 'value',
  Currency = 'currency',
  Ecommerce = 'ecommerce',
}

enum GtmEventEcommerceEnum {
  ActionField = 'actionField',
  Products = 'products',
}

enum GtmEventEcommerceActionFieldEnum {
  Id = 'id',
  Affiliation = 'affiliation',
  Revenue = 'revenue',
  Tax = 'tax',
  Shipping = 'shipping',
}

enum GtmEventEcommerceProductsEnum {
  Id = 'id',
  Name = 'name',
  Price = 'price',
  Brand = 'brand',
  Category = 'category',
  Variant = 'variant',
  Position = 'position',
  Quantity = 'quantity',
}

interface GtmEventEcommerceActionField {
  [GtmEventEcommerceActionFieldEnum.Id]: string;
  [GtmEventEcommerceActionFieldEnum.Affiliation]: string;
  [GtmEventEcommerceActionFieldEnum.Revenue]?: number | undefined;
  [GtmEventEcommerceActionFieldEnum.Tax]: number | undefined;
  [GtmEventEcommerceActionFieldEnum.Shipping]?: string;
}

interface GtmEventEcommerceProduct {
  [GtmEventEcommerceProductsEnum.Id]: string;
  [GtmEventEcommerceProductsEnum.Name]: string;
  [GtmEventEcommerceProductsEnum.Price]: number | undefined;
  [GtmEventEcommerceProductsEnum.Brand]: string;
  [GtmEventEcommerceProductsEnum.Variant]: string;
  [GtmEventEcommerceProductsEnum.Quantity]: number | undefined;
  [GtmEventEcommerceProductsEnum.Category]?: string;
  [GtmEventEcommerceProductsEnum.Position]?: string;
}

interface GtmEventEcommerce {
  [GtmEventEcommerceEnum.ActionField]: GtmEventEcommerceActionField;
  [GtmEventEcommerceEnum.Products]: GtmEventEcommerceProduct[];
}

interface GtmEvent {
  [GtmEventEnum.Event]: string;
  [GtmEventEnum.Ecommerce]?: GtmEventEcommerce;
  [GtmEventEnum.Value]?: string;
}

export {
  GtmEventEnum,
  GtmEventEcommerceEnum,
  GtmEventEcommerceActionFieldEnum,
  GtmEventEcommerceProductsEnum,
  GtmEventEcommerceActionField,
  GtmEventEcommerceProduct,
  GtmEventEcommerce,
  GtmEvent,
};
