export const ProxyDateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
export const ApiDateFormat = 'yyyy-MM-dd';

export const PRODUCT_ROWS_SHOWN = 5;

export const PanelRightVisibleForComponent = [
  'QuotationInformationForm',
  'QuotationProposals',
  'QuotationRecapDeclarations',
];
